import React, { useState, useEffect, useRef } from 'react';
import ImageCarousel from '../components/ImageCarousel';
import Loader from './Loader';
import { Project } from '../models/Project';
import throttle from 'lodash/throttle';

interface ListingDetailsProps {
  project: Project;
  onClose: () => void;
}

const ListingDetails: React.FC<ListingDetailsProps> = ({ project, onClose }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [carouselHeight, setCarouselHeight] = useState(260); // Initial height of the carousel
  const [blurAmount, setBlurAmount] = useState(0); // Initial blur amount
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollTop = modalRef.current?.scrollTop || 0;
      const maxBlur = 5; // Reduced max blur for performance
      const maxHeightReduction = 200; // Max height reduction in pixels

      // Calculate the new height (minimum height 100px)
      const newHeight = Math.max(260 - scrollTop, 100);
      setCarouselHeight(newHeight);

      // Calculate the blur amount based on scroll, maxing out at `maxBlur`
      const blurStrength = Math.min(scrollTop / 30, maxBlur); // Gradual blur calculation
      setBlurAmount(blurStrength);
    }, 100); // Throttled to run at most every 100ms

    modalRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      modalRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!project) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <Loader />
      </div>
    );
  }

  const handleGetMoreInformation = () => window.open(project.href, '_blank');

  const firstUnit = project.projectFeatures.units?.[0] || {};
  const pictures = firstUnit.pictures || [];
  const price = firstUnit.price !== undefined ? firstUnit.price : 'N/A';

  return (
    <div
      ref={modalRef}
      className="fixed inset-0 z-50 bg-white text-gray-900 transition-transform transform overflow-y-auto"
      style={{ contain: 'layout size style' }}
    >
      <div
        className="relative w-full overflow-hidden transition-all duration-300 ease-out"
        style={{
          height: `${carouselHeight}px`, // Dynamic height with smooth transition
          filter: `blur(${blurAmount}px)`, // Applying blur
        }}
      >
        <ImageCarousel pictures={project.projectFeatures.units?.[0].pictures || []} />
        <button
          className="absolute top-4 left-4 bg-white bg-opacity-80 text-gray-900 rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray-100 focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>
      </div>

      {/* Main Content with Padding to Avoid Overlap */}
      <div
        className="px-8 py-6 flex-grow overflow-y-auto space-y-10"
        style={{
          paddingTop: `20px`, // Ensure content starts below carousel
        }}
      >
        {/* Project Overview */}
        <div>
          <h1 className="text-3xl font-semibold">{project.name}</h1>
          <h2 className="text-xl text-gray-500">{project.cityName}</h2>
          <p className="text-gray-700 text-lg mt-4">
            {isDescriptionExpanded ? project.description : `${project.description.slice(0, 400)}`}
          </p>
          {project.description && project.description.length > 400 && (
            <button
              onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
              className="text-blue-600 hover:underline mt-2 block"
            >
              {isDescriptionExpanded ? 'Read Less' : 'Read More'}
            </button>
          )}
        </div>

        {/* Key Features Section */}
        <div className="space-y-6 border-t pt-6">
          <h2 className="text-2xl font-semibold">Key Features</h2>
          <p>Total Land Area: <span className="font-medium text-gray-800">{project.projectFeatures.totalLandArea || 'N/A'} m²</span></p>
          <p>Units Available: <span className="font-medium text-gray-800">{project.projectFeatures.unitsAvailable || 'N/A'}</span></p>
          <p>Completion Date: <span className="font-medium text-gray-800">{project.projectFeatures.completionDate || 'N/A'}</span></p>
        </div>

        {/* Units Details */}
        <div className="border-t pt-6 space-y-4">
          <h2 className="text-2xl font-semibold">Units</h2>
          {project.projectFeatures.units?.map((unit, index) => (
            <div key={index} className="rounded-lg pt-6">
              <p className="text-2xl font-bold">{unit.unitName || 'N/A'}</p>
              <p className="text-xl font-semibold text-blue-700">
                ${unit.price ? new Intl.NumberFormat('en-US').format(unit.price) : 'N/A'}
              </p>
              <p>{unit.buildingArea || 'N/A'} m²</p>
              {unit.pictures && unit.pictures.length > 0 && (
                <div className="mt-2">
                  <ImageCarousel pictures={unit.pictures} />
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Construction Details */}
        <div className="border-t pt-6">
          <h2 className="text-2xl font-semibold">Construction</h2>
          <p>Progress: <span className="font-medium">{project.constructionDetails?.constructionProgress || 'N/A'}%</span></p>
          <p>Warranty Period: <span className="font-medium">{project.constructionDetails?.warrantyPeriod || 'N/A'} years</span></p>
          <p>Materials: <span className="font-medium">{project.constructionDetails?.constructionMaterials || 'N/A'}</span></p>
        </div>

        {/* Investment Information */}
        <div className="border-t pt-6">
          <h2 className="text-2xl font-semibold">Financial</h2>
          <p>ROI: <span className="font-medium">{project.investmentInformation.minROI}% - {project.investmentInformation.maxROI}%</span></p>
          <p>Break-even Period: <span className="font-medium">{project.investmentInformation.breakEvenPeriod || 'N/A'} years</span></p>
          <p>Expected ROI: <span className="font-medium">{project.investmentInformation.expectedROI || 'N/A'}%</span></p>
        </div>
      </div>

      {/* Sticky Footer */}
      <div className="sticky bottom-0 px-8 py-6 w-full bg-gray-100 z-20">
        <button
          onClick={handleGetMoreInformation}
          className="w-full bg-blue-600 text-white py-4 rounded-lg font-medium text-lg hover:bg-blue-500 transition duration-300"
        >
          Get more information
        </button>
      </div>
    </div>
  );
};

export default ListingDetails;
