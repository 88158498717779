import { useState } from 'react';
import apiClient from '../api/apiClientInterceptor';

// Custom hook to handle the login request
const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to send the POST request
  const handleLogin = async (email: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.post('/login', { email });
      setLoading(false);
      return response.data; // Return response data if needed
    } catch (err: any) {
      setLoading(false);
      setError(err.response?.data?.message || 'Login failed. Please try again.');
      return null;
    }
  };

  return { login: handleLogin, loading, error };
};

export default useLogin;
