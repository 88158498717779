import { getToken } from './useToken';
import apiClient from "../api/apiClientInterceptor";
//import { showModal } from '../modalTrigger'; // Adjust the import path as needed

const addRealEstate = async (formData: any) => {
  try {
    const { token, isLoggedIn } = await getToken();
    
    // Optional: You can handle the case when the user is not logged in
    /*if (!isLoggedIn) {
      console.log('No token found');
      showModal();
      return;
    }*/

    // Make the API request with cityName, page, and limit
    const response = await apiClient.post(
      '/add-real-estate-info', formData,  // Pass page and limit as part of the payload
      {
        headers: {
          'Authorization': `Bearer ${token || ''}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error('Error fetching listings:', error);
    throw error; // Propagate the error for further handling
  }
};

export default {
  addRealEstate
};
