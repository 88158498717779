import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useProject from '../hooks/useProject';
import useAmenities from '../hooks/useAmenities';
import Loader from '../components/Loader';
import { Amenities, Project } from '../models/Project';
import PdfUploader from "../components/PdfUploader";

interface Amenity {
    _id: string;
    name: string;
    type: 'shared' | 'private';
    icon: string;
}

interface FormData {
    name: string;
    cityName: string;
    cityArea: string;
    country: string;
    href: string;
    description: string;
    projectFeatures: {
        totalLandArea: string;
        completionDate: string;
        unitsAvailable: string;
        units: {
            unitName: string;
            price: string;
            buildingArea: string;
            pictures: string[];
        }[];
    };
    constructionDetails: {
        constructionProgress?: string;
        warrantyPeriod?: string;
        constructionMaterials: string;
    };
    investmentInformation: {
        minROI: string;
        maxROI: string;
        breakEvenPeriod?: string;
        expectedROI?: string;
    };
    amenities: {
        sharedAmenities: Record<string, boolean>;
        privateAmenities: Record<string, boolean>;
    };
}

const defaultFormData: FormData = {
    name: '',
    cityName: '',
    cityArea: '',
    country: '',
    href: '',
    description: '',
    projectFeatures: {
        totalLandArea: '',
        completionDate: '',
        unitsAvailable: '',
        units: [
            {
                unitName: '',
                price: '',
                buildingArea: '',
                pictures: [''] // Initialize with an empty array
            }
        ]
    },
    constructionDetails: {
        constructionProgress: '',
        warrantyPeriod: '',
        constructionMaterials: ''
    },
    investmentInformation: {
        minROI: '',
        maxROI: '',
        breakEvenPeriod: '',
        expectedROI: ''
    },
    amenities: { sharedAmenities: {}, privateAmenities: {} }
};

const LOCAL_STORAGE_KEY = 'projectFormData';

const AddOrEditProject: React.FC = () => {
    const { identifier } = useParams<{ identifier: string }>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || JSON.stringify(defaultFormData))
    );
    const [availableAmenities, setAvailableAmenities] = useState<Amenity[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [submissionStatus, setSubmissionStatus] = useState<'success' | 'error' | null>(null);

    useEffect(() => {
        const fetchAmenitiesAndProjectData = async () => {
            try {
                setLoading(true); // Start loading
    
                // Fetch available amenities
                const amenities = await useAmenities.getAllAmenities();
                const sharedAmenities = amenities
                    .filter((amenity) => amenity.type === 'shared')
                    .reduce((acc, amenity) => ({ ...acc, [amenity.name]: false }), {} as Record<string, boolean>);
                const privateAmenities = amenities
                    .filter((amenity) => amenity.type === 'private')
                    .reduce((acc, amenity) => ({ ...acc, [amenity.name]: false }), {} as Record<string, boolean>);
    
                // Set available amenities for display in checkboxes
                setAvailableAmenities(amenities);
    
                if (identifier) {
                    const project = await useProject.getDetails(identifier);
                    const projectData = project.data;
    
                    // Map project amenities to match the boolean structure of formData
                    const selectedSharedAmenities = { ...sharedAmenities };
                    const selectedPrivateAmenities = { ...privateAmenities };
    
                    // Update selected amenities based on projectData
                    if (projectData.amenities?.sharedAmenities) {
                        for (const [amenityName, isSelected] of Object.entries(projectData.amenities.sharedAmenities)) {
                            if (amenityName in selectedSharedAmenities) {
                                selectedSharedAmenities[amenityName] = isSelected;
                            }
                        }
                    }
    
                    if (projectData.amenities?.privateAmenities) {
                        for (const [amenityName, isSelected] of Object.entries(projectData.amenities.privateAmenities)) {
                            if (amenityName in selectedPrivateAmenities) {
                                selectedPrivateAmenities[amenityName] = isSelected;
                            }
                        }
                    }
    
                    // Populate formData with project data and amenities
                    setFormData({
                        name: projectData.name || '',
                        cityName: projectData.cityName || '',
                        cityArea: projectData.cityArea || '',
                        country: projectData.country || '',
                        href: projectData.href || '',
                        description: projectData.description || '',
                        projectFeatures: {
                            totalLandArea: String(projectData.projectFeatures?.totalLandArea || ''),
                            completionDate: String(projectData.projectFeatures?.completionDate || ''),
                            unitsAvailable: String(projectData.projectFeatures?.unitsAvailable || ''),
                            units: (projectData.projectFeatures?.units || []).map(unit => ({
                                unitName: unit.unitName || '',
                                price: String(unit.price || ''),
                                buildingArea: String(unit.buildingArea || ''),
                                pictures: unit.pictures || ['']
                            }))
                        },
                        constructionDetails: {
                            constructionProgress: String(projectData.constructionDetails?.constructionProgress || ''),
                            warrantyPeriod: String(projectData.constructionDetails?.warrantyPeriod || ''),
                            constructionMaterials: projectData.constructionDetails?.constructionMaterials || ''
                        },
                        investmentInformation: {
                            minROI: String(projectData.investmentInformation?.minROI || ''),
                            maxROI: String(projectData.investmentInformation?.maxROI || ''),
                            breakEvenPeriod: String(projectData.investmentInformation?.breakEvenPeriod || ''),
                            expectedROI: String(projectData.investmentInformation?.expectedROI || '')
                        },
                        amenities: {
                            sharedAmenities: selectedSharedAmenities,
                            privateAmenities: selectedPrivateAmenities
                        }
                    });
                } else {
                    // For a new project, set default formData including default amenities
                    setFormData({
                        ...defaultFormData,
                        amenities: {
                            sharedAmenities,
                            privateAmenities
                        }
                    });
                }
            } catch (err) {
                console.error('Failed to load project data:', err);
                setError('Failed to load project data. Please try again later.');
            } finally {
                setLoading(false); // Stop loading
            }
        };
    
        fetchAmenitiesAndProjectData();
    }, [identifier]);

    const handleProcessComplete = (data: any) => {
        setFormData(data); // Update form data with the processed result
    };


    const addUnitPictureField = (unitIndex: number) => {
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: prevFormData.projectFeatures.units.map((unit, idx) =>
                        idx === unitIndex
                            ? { ...unit, pictures: [...(unit.pictures || []), ''] } // Initialize as empty string if new
                            : unit
                    )
                }
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };

    const removeUnitPictureField = (unitIndex: number, pictureIndex: number) => {
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: prevFormData.projectFeatures.units.map((unit, idx) =>
                        idx === unitIndex
                            ? {
                                  ...unit,
                                  pictures: unit.pictures.filter((_, picIdx) => picIdx !== pictureIndex)
                              }
                            : unit
                    )
                }
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };
    
    const addUnit = () => {
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: [
                        ...(prevFormData.projectFeatures.units || []),
                        { unitName: '', price: '', buildingArea: '', pictures: [] } // Initialize pictures array here
                    ]
                }
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };
    
    const removeUnit = (unitIndex: number) => {
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: prevFormData.projectFeatures.units.filter((_, index) => index !== unitIndex)
                }
            };
    
            // Save the updated formData to localStorage after removal
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
    
            return updatedFormData;
        });
    };
    
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        nestedField?: 'sharedAmenities' | 'privateAmenities' | 'projectFeatures' | 'investmentInformation' | 'constructionDetails',
        index?: number
    ) => {
        const { name, value, type } = e.target;
        const inputValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    
        setFormData((prevFormData) => {
            const updatedFormData = nestedField === 'sharedAmenities' || nestedField === 'privateAmenities'
                ? {
                    ...prevFormData,
                    amenities: {
                        ...prevFormData.amenities,
                        [nestedField]: {
                            ...prevFormData.amenities[nestedField],
                            [name]: inputValue
                        }
                    }
                }
                : nestedField === 'projectFeatures'
                    ? {
                        ...prevFormData,
                        projectFeatures: {
                            ...prevFormData.projectFeatures,
                            [name]: inputValue
                        }
                    }
                : nestedField === 'investmentInformation'
                    ? {
                        ...prevFormData,
                        investmentInformation: {
                            ...prevFormData.investmentInformation,
                            [name]: inputValue
                        }
                    }
                : nestedField === 'constructionDetails'
                    ? {
                        ...prevFormData,
                        constructionDetails: {
                            ...prevFormData.constructionDetails,
                            [name]: inputValue
                        }
                    }
                : { ...prevFormData, [name]: inputValue as string };
    
            // Save the updated form data to local storage
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };
    

    const handleUnitChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        unitIndex: number
    ) => {
        const { name, value } = e.target;
    
        setFormData(prevFormData => {
            // Update the specific unit within the units array
            const updatedUnits = prevFormData.projectFeatures.units.map((unit, idx) =>
                idx === unitIndex ? { ...unit, [name]: value } : unit
            );
    
            // Construct updated formData
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: updatedUnits
                }
            };
    
            // Save updated formData to localStorage
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
    
            return updatedFormData;
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        // Prepare the amenities object in the required format
        const amenities: Amenities = {
            sharedAmenities: formData.amenities.sharedAmenities,
            privateAmenities: formData.amenities.privateAmenities
        };
    
        // Prepare the complete submission data including amenities
        const submissionData: Project = {
            ...formData,
            projectFeatures: {
                totalLandArea: parseFloat(formData.projectFeatures.totalLandArea) || 0,
                unitsAvailable: parseInt(formData.projectFeatures.unitsAvailable) || 0,
                completionDate: formData.projectFeatures.completionDate,
                units: formData.projectFeatures.units.map(unit => ({
                    unitName: unit.unitName,
                    price: parseFloat(unit.price), // Already a string in formData
                    buildingArea: parseFloat(unit.buildingArea), // Already a string in formData
                    pictures: unit.pictures || []
                }))
            },
            constructionDetails: {
                constructionProgress: formData.constructionDetails.constructionProgress
                    ? parseFloat(formData.constructionDetails.constructionProgress)
                    : undefined,
                warrantyPeriod: formData.constructionDetails.warrantyPeriod
                    ? parseInt(formData.constructionDetails.warrantyPeriod)
                    : undefined,
                constructionMaterials: formData.constructionDetails.constructionMaterials
            },
            investmentInformation: {
                minROI: parseFloat(formData.investmentInformation.minROI) || 0,
                maxROI: parseFloat(formData.investmentInformation.maxROI) || 0,
                breakEvenPeriod: formData.investmentInformation.breakEvenPeriod
                    ? parseFloat(formData.investmentInformation.breakEvenPeriod)
                    : undefined,
                expectedROI: formData.investmentInformation.expectedROI
                    ? parseFloat(formData.investmentInformation.expectedROI)
                    : undefined
            },
            amenities // Add the structured amenities object here
        };
    
        try {
            let response;
            if (identifier) {
                // Update existing project
                response = await useProject.updateProject(identifier, submissionData);
            } else {
                // Add a new project
                response = await useProject.addProject(submissionData);
            }
    
            if (response.status === 200 || response.status === 201) {
                setSubmissionStatus('success');
                setFormData(defaultFormData); // Reset form data after successful submission
                setError(null);
                localStorage.removeItem(LOCAL_STORAGE_KEY);
                navigate('/admin'); // Navigate to the admin page or another page on success
            }
        } catch (err) {
            setSubmissionStatus('error');
            setError('Failed to submit the project. Please check the required fields.');
        }
    };

    const handleUnitPictureChange = (unitIndex: number, pictureIndex: number, value: string) => {
        setFormData(prevFormData => {
            const updatedUnits = prevFormData.projectFeatures.units.map((unit, idx) =>
                idx === unitIndex
                    ? {
                          ...unit,
                          pictures: unit.pictures.map((pic, picIdx) =>
                              picIdx === pictureIndex ? value : pic
                          )
                      }
                    : unit
            );
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: updatedUnits
                }
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };

    if (loading) return <Loader />;

    return (
        <div className="min-h-screen bg-gray-100 p-6 flex justify-center items-center">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-2xl w-full">
                <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">
                    {identifier ? 'Edit Project' : 'Add Project'}
                </h2>
                {submissionStatus === 'success' && (
                    <p className="mt-4 text-green-600 text-center">Submitted successfully!</p>
                )}
                {submissionStatus === 'error' && (
                    <p className="mt-4 text-red-600 text-center">Failed to submit. Please try again.</p>
                )}
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}

                <PdfUploader onProcessComplete={handleProcessComplete} />

                <form onSubmit={handleSubmit} className="space-y-4">
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required placeholder="Project Name" className="w-full p-2 border" />
                    <input type="text" name="cityName" value={formData.cityName} onChange={handleChange} required placeholder="City Name" className="w-full p-2 border" />
                    <input type="text" name="cityArea" value={formData.cityArea} onChange={handleChange} required placeholder="City Area" className="w-full p-2 border" />
                    <input type="text" name="country" value={formData.country} onChange={handleChange} required placeholder="Country" className="w-full p-2 border" />
                    <input type="url" name="href" value={formData.href} onChange={handleChange} required placeholder="Project URL" className="w-full p-2 border" />
                    <textarea name="description" value={formData.description} onChange={handleChange} required placeholder="Description" className="w-full p-2 border" />

                    <label className="block">Project Features</label>
                    <input type="number" name="totalLandArea" value={formData.projectFeatures.totalLandArea} onChange={(e) => handleChange(e, 'projectFeatures')} placeholder="Total Land Area" className="w-full p-2 border" />
                    <input type="string" name="completionDate" value={formData.projectFeatures.completionDate} onChange={(e) => handleChange(e, 'projectFeatures')} placeholder="Completion Date" className="w-full p-2 border" />
                    <input type="number" name="unitsAvailable" value={formData.projectFeatures.unitsAvailable} onChange={(e) => handleChange(e, 'projectFeatures')} placeholder="Units Available" className="w-full p-2 border" />
                    <label className="block">Units</label>
                    {(formData.projectFeatures.units || []).map((unit, unitIndex) => (
                    <div key={unitIndex} className="border p-4 mb-4">
                        <h3 className="font-semibold">Unit {unitIndex + 1}</h3>
                        
                        <input
                            type="text"
                            name="unitName"
                            value={unit.unitName}
                            onChange={(e) => handleUnitChange(e, unitIndex)}
                            placeholder="Unit Name"
                            className="w-full p-2 border mb-2"
                        />
                        
                        <input
                            type="text"
                            name="price"
                            value={unit.price}
                            onChange={(e) => handleUnitChange(e, unitIndex)}
                            placeholder="Price"
                            className="w-full p-2 border mb-2"
                        />
                        
                        <input
                            type="text"
                            name="buildingArea"
                            value={unit.buildingArea}
                            onChange={(e) => handleUnitChange(e, unitIndex)}
                            placeholder="Building Area"
                            className="w-full p-2 border mb-2"
                        />

                        <label className="block">Unit Pictures</label>
                        {(unit.pictures|| []).map((picture, pictureIndex) => (
                            <div key={pictureIndex} className="flex items-center space-x-2 mb-2">
                                <input
                                    type="text"
                                    value={picture}
                                    onChange={(e) =>
                                        handleUnitPictureChange(unitIndex, pictureIndex, e.target.value)
                                    }
                                    placeholder={`Picture URL ${pictureIndex + 1}`}
                                    className="w-full p-2 border"
                                />
                                <button
                                    type="button"
                                    onClick={() => removeUnitPictureField(unitIndex, pictureIndex)}
                                    className="text-red-500"
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                        <div>
                            <button type="button" onClick={() => addUnitPictureField(unitIndex)} className="text-blue-500">Add Picture</button>
                        </div>
                        <div>
                        <button
                            type="button"
                            onClick={() => removeUnit(unitIndex)}
                            className="text-red-500 mt-2"
                        >
                            Remove Unit
                        </button>
                        </div>
                    </div>
                ))}

                {/* Button to add a new unit */}
                <button type="button" onClick={addUnit} className="text-blue-500">
                    Add Unit
                </button>


                    <label className="block">Investment Information</label>
                    <input type="number" name="minROI" value={formData.investmentInformation.minROI} onChange={(e) => handleChange(e, 'investmentInformation')} placeholder="Minimum ROI" className="w-full p-2 border" />
                    <input type="number" name="maxROI" value={formData.investmentInformation.maxROI} onChange={(e) => handleChange(e, 'investmentInformation')} placeholder="Maximum ROI" className="w-full p-2 border" />

                    <label className="block">Amenities</label>
                    <div className="mb-4">
                        <h3 className="font-semibold">Shared Amenities</h3>
                        {availableAmenities
                            .filter(amenity => amenity.type === 'shared')
                            .map(amenity => (
                                <div key={amenity._id} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name={amenity.name}
                                        checked={formData.amenities.sharedAmenities[amenity.name] || false}
                                        onChange={(e) => handleChange(e, 'sharedAmenities')}
                                    />
                                    <label>{amenity.name}</label>
                                </div>
                            ))}
                    </div>

                    <div className="mb-4">
                        <h3 className="font-semibold">Private Amenities</h3>
                        {availableAmenities
                            .filter(amenity => amenity.type === 'private')
                            .map(amenity => (
                                <div key={amenity._id} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name={amenity.name}
                                        checked={formData.amenities.privateAmenities[amenity.name] || false}
                                        onChange={(e) => handleChange(e, 'privateAmenities')}
                                    />
                                    <label>{amenity.name}</label>
                                </div>
                            ))}
                    </div>

                    <label className="block">Construction Details</label>
                    <input
                        type="number"
                        name="constructionProgress"
                        value={formData.constructionDetails.constructionProgress}
                        onChange={(e) => handleChange(e, 'constructionDetails')}
                        placeholder="Construction Progress (%)"
                        className="w-full p-2 border"
                    />

                    <input
                        type="number"
                        name="warrantyPeriod"
                        value={formData.constructionDetails.warrantyPeriod}
                        onChange={(e) => handleChange(e, 'constructionDetails')}
                        placeholder="Warranty Period (years)"
                        className="w-full p-2 border"
                    />

                    <input
                        type="text"
                        name="constructionMaterials"
                        value={formData.constructionDetails.constructionMaterials}
                        onChange={(e) => handleChange(e, 'constructionDetails')}
                        placeholder="Construction Materials"
                        className="w-full p-2 border"
                    />

                    <button type="submit" className="w-full py-3 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300">
                        {identifier ? 'Update Project' : 'Add Project'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddOrEditProject;