import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useRealEstate from '../hooks/useRealEstate';
import useListing from '../hooks/useListing';
import Loader from '../components/Loader';

interface FormData {
    cityName: string;
    cityArea: string;
    country: string;
    price: string;
    href: string;
    pictures: string[];
    description: string;
    bedrooms: string[];
    bathrooms: string[];
    size: string[];
    minRoi: string;
    maxRoi: string;
    unitsAvailable: string;
}

const defaultFormData: FormData = {
    cityName: '',
    cityArea: '',
    country: '',
    price: '',
    href: '',
    pictures: [''],
    description: '',
    bedrooms: [''],
    bathrooms: [''],
    size: [''],
    minRoi: '',
    maxRoi: '',
    unitsAvailable: ''
};

const AddOrEditRealEstateListing: React.FC = () => {
    const { identifier } = useParams<{ identifier: string }>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>(defaultFormData);
    const [error, setError] = useState<string | null>(null);
    const [submissionStatus, setSubmissionStatus] = useState<'success' | 'error' | null>(null);

    useEffect(() => {
        if (identifier) {
          const fetchListingData = async () => {
            try {
              setLoading(true); // Start loading
              const listingData = await useListing.getDetails(identifier);
    
              setFormData({
                cityName: listingData.cityName || '',
                cityArea: listingData.cityArea || '',
                country: listingData.country || '',
                price: String(listingData.price || ''),
                href: listingData.href || '',
                pictures: listingData.pictures || [''],
                description: listingData.description || '',
                bedrooms: listingData.bedrooms.map(String) || [''],
                bathrooms: listingData.bathrooms.map(String) || [''],
                size: listingData.size.map(String) || [''],
                minRoi: String(listingData.minRoi || ''),
                maxRoi: String(listingData.maxRoi || ''),
                unitsAvailable: String(listingData.unitsAvailable || ''),
              });
            } catch (err) {
              console.error('Failed to load listing data:', err);
              setError('Failed to load listing data. Please try again later.');
            } finally {
              setLoading(false); // Stop loading
            }
          };
          fetchListingData();
        }
      }, [identifier]);

      const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index?: number,
        type?: 'bedrooms' | 'bathrooms' | 'pictures' | 'size'
    ) => {
        const { name, value } = e.target;
    
        if (type && index !== undefined) {
            setFormData(prevFormData => {
                const updatedArray = [...prevFormData[type]];
                updatedArray[index] = value;
                return { ...prevFormData, [type]: updatedArray };
            });
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    
        // Validation check for minRoi and maxRoi
        if (name === "minRoi" || name === "maxRoi") {
            const minRoi = name === "minRoi" ? parseFloat(value) : parseFloat(formData.minRoi);
            const maxRoi = name === "maxRoi" ? parseFloat(value) : parseFloat(formData.maxRoi);
    
            if (!isNaN(minRoi) && !isNaN(maxRoi)) {
                if (minRoi > maxRoi) {
                    setError("Minimum ROI should not be greater than Maximum ROI.");
                } else {
                    setError(null); // Clear error if the condition is satisfied
                }
            }
        } else {
            setError(null); // Clear error if not related to ROI fields
        }
    };
    

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        const submissionData = {
            ...formData,
            price: parseFloat(formData.price) || 0,
            bedrooms: formData.bedrooms.map(bed => parseFloat(bed) || 0),
            bathrooms: formData.bathrooms.map(bath => parseFloat(bath) || 0),
            size: formData.size.map(sz => parseFloat(sz) || 0),
            minRoi: parseFloat(formData.minRoi) || 0,
            maxRoi: parseFloat(formData.maxRoi) || 0,
            unitsAvailable: parseInt(formData.unitsAvailable) || 0,
        };

        try {
            if (identifier) {
                const response = await useListing.updateListing(identifier, submissionData);
                if (response.status === 200) {
                    setSubmissionStatus('success');
                    navigate('/admin');
                }
            } else {
                const response = await useRealEstate.addRealEstate(submissionData);
                if (response.status === 200 || response.status === 201) {
                    setSubmissionStatus('success');
                    setFormData(defaultFormData);
                    setError(null);
                    localStorage.removeItem('realEstateFormData');
                    navigate('/admin');
                }
            }
        } catch (err) {
            setSubmissionStatus('error');
            setError('Failed to submit the listing. Please check the required fields.');
        }
    };

    const addField = (type: 'bedrooms' | 'bathrooms' | 'pictures' | 'size') => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [type]: [...prevFormData[type], '']
        }));
    };

    const removeField = (index: number, type: 'bedrooms' | 'bathrooms' | 'pictures' | 'size') => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [type]: prevFormData[type].filter((_, i) => i !== index)
        }));
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen bg-gray-100 p-6 flex justify-center items-center">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-2xl w-full">
                <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">
                    {identifier ? 'Edit Real Estate Listing' : 'Add Real Estate Listing'}
                </h2>

                {submissionStatus === 'success' && (
                    <p className="mt-4 text-green-600 text-center">Submitted successfully!</p>
                )}
                {submissionStatus === 'error' && (
                    <p className="mt-4 text-red-600 text-center">Failed to submit. Please try again.</p>
                )}
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}

                <form onSubmit={handleSubmit} className="space-y-4">
                    <input type="text" name="cityName" value={formData.cityName} onChange={handleChange} required placeholder="City Name" className="w-full p-2 border" />
                    <input type="text" name="cityArea" value={formData.cityArea} onChange={handleChange} required placeholder="City Area" className="w-full p-2 border" />
                    <input type="text" name="country" value={formData.country} onChange={handleChange} required placeholder="Country" className="w-full p-2 border" />
                    <input type="number" name="price" value={formData.price} onChange={handleChange} required placeholder="Price" className="w-full p-2 border" />
                    <input type="url" name="href" value={formData.href} onChange={handleChange} required placeholder="Listing URL" className="w-full p-2 border" />

                    {/* Pictures */}
                    <label className="block">Pictures</label>
                    {formData.pictures.map((picture, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <input type="text" value={picture} onChange={(e) => handleChange(e, index, 'pictures')} placeholder={`Picture URL ${index + 1}`} className="w-full p-2 border" />
                            <button type="button" onClick={() => removeField(index, 'pictures')} className="text-red-500">Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => addField('pictures')} className="text-blue-500">Add Picture</button>

                    <textarea name="description" value={formData.description} onChange={handleChange} required placeholder="Description" className="w-full p-2 border" />

                                        {/* Bedrooms */}
                                        <label className="block">Bedrooms</label>
                    {formData.bedrooms.map((bedroom, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <input type="number" value={bedroom} onChange={(e) => handleChange(e, index, 'bedrooms')} placeholder={`Bedroom ${index + 1}`} className="w-full p-2 border" />
                            <button type="button" onClick={() => removeField(index, 'bedrooms')} className="text-red-500">Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => addField('bedrooms')} className="text-blue-500">Add Bedroom</button>

                    {/* Bathrooms */}
                    <label className="block">Bathrooms</label>
                    {formData.bathrooms.map((bathroom, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <input type="number" value={bathroom} onChange={(e) => handleChange(e, index, 'bathrooms')} placeholder={`Bathroom ${index + 1}`} className="w-full p-2 border" />
                            <button type="button" onClick={() => removeField(index, 'bathrooms')} className="text-red-500">Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => addField('bathrooms')} className="text-blue-500">Add Bathroom</button>

                    {/* Size */}
                    <label className="block">Size (in square meters)</label>
                    {formData.size.map((size, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <input type="number" value={size} onChange={(e) => handleChange(e, index, 'size')} placeholder={`Size ${index + 1}`} className="w-full p-2 border" />
                            <button type="button" onClick={() => removeField(index, 'size')} className="text-red-500">Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => addField('size')} className="text-blue-500">Add Size</button>

                    <input type="number" name="minRoi" value={formData.minRoi} onChange={handleChange} required placeholder="Minimum ROI" className="w-full p-2 border" />
                    <input type="number" name="maxRoi" value={formData.maxRoi} onChange={handleChange} required placeholder="Maximum ROI" className="w-full p-2 border" />
                    <input type="number" name="unitsAvailable" value={formData.unitsAvailable} onChange={handleChange} required placeholder="Units Available" className="w-full p-2 border" />

                    
                    <button type="submit" className="w-full py-3 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300">
                        {identifier ? 'Update Listing' : 'Add Listing'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddOrEditRealEstateListing;
