import React from 'react';
import { Project } from '../models/Project';

interface PropertyCardProps {
  project: Project;
  onClick: () => void;
  onLoginClick: () => void;
  isAdmin?: boolean; // Indicates admin or editing mode
  onEditClick?: () => void; // Callback for edit button click
}

const PropertyCard: React.FC<PropertyCardProps> = ({ project, onClick, onLoginClick, isAdmin = false, onEditClick }) => {
  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the main card click event
    onEditClick && onEditClick();
  };

  return (
    <div
      className={`relative block card w-full bg-white shadow-xl border border-gray-300 hover:shadow-2xl transition-shadow duration-300 rounded-lg ${project.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={!project.disabled ? onClick : undefined}
    >
      {isAdmin && (
        <button 
          onClick={handleEditClick}
          className="absolute top-2 z-10 right-2 bg-white p-1 rounded-full shadow-lg hover:bg-gray-100 transition duration-200"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-gray-700">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-6.036a2.5 2.5 0 113.536 3.536L7.5 20.5H4v-3.5L16.732 3.732z" />
          </svg>
        </button>
      )}

      {/* Property Image */}
      <figure className="relative pb-2/3">
        <img
          src={project.projectFeatures.units?.[0]?.pictures?.[0] || '/path/to/default-image.jpg'} // Use a fallback image if pictures[0] is unavailable
          className="h-full w-full object-cover rounded-t-lg max-h-64"
          alt="Property"
          style={{ pointerEvents: 'none' }} // Prevents the image from blocking clicks on the card
        />
        {/* Developer Logo Overlay */}
        {project.developer?.logo && (
          <img
            src={project.developer.logo}
            alt="Developer Logo"
            className="absolute bottom-0 right-0 h-8 w-30 object-contain bg-white p-1 rounded-tl-lg border border-white"
            style={{ pointerEvents: 'none' }}
          />
        )}
      </figure>

      {/* Property Details */}
      <div className="card-body p-4 relative">
        <h2 className="card-title text-gray-800 font-semibold text-lg flex justify-between items-center">
          <span>
            price 
            {/*{`$${new Intl.NumberFormat('en-US').format(listing.price)}`}*/}
          </span>
          <span className="text-gray-500 text-sm">
            {project.projectFeatures.unitsAvailable} units available
          </span>
        </h2>

        <p className="text-gray-600 mt-2">
          Project sizes m²
        </p>

        {/* Display Min - Max ROI */}
        <p className="text-gray-600 mt-1">
          ROI: {project.investmentInformation.minROI}% - {project.investmentInformation.maxROI}%
        </p>
      </div>
    </div>
  );
};

export default PropertyCard;