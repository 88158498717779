import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropertyCard from '../components/PropertyCard';
import DropDown from '../components/Dropdown';
import Loader from '../components/Loader';
import useCity from '../hooks/useCity';
import useProject from '../hooks/useProject';
import { Project } from '../models/Project';
import { City } from '../models/City';
import ListingDetails from '../components/ListingDetails';
import Logo from '../../src/BektuAI.svg';
import useLogin from '../hooks/useLogin';
import SlideInMenu from '../components/Menu';

const Home: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [showLoginFooter, setShowLoginFooter] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { login, loading, error } = useLogin();
  const [menuOpen, setMenuOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loaderDivRef = useRef<HTMLDivElement | null>(null);

  // Toggle scroll lock on modal visibility
  useEffect(() => {
    document.body.style.overflow = isModalVisible ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto'; // Reset when component unmounts
    };
  }, [isModalVisible]);

  // Fetch cities on mount
  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      setPage(1);
      setProjects([]);
      fetchProjects(selectedCity, 1, true);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (page > 1 && selectedCity) {
      fetchProjects(selectedCity, page, false);
    }
  }, [page]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loadingMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }, { threshold: 1 });

    if (loaderDivRef.current) {
      observer.observe(loaderDivRef.current);
    }

    return () => {
      if (loaderDivRef.current) observer.unobserve(loaderDivRef.current);
    };
  }, [hasMore, loadingMore]);

  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const fetchProjects = async (cityName: string, page: number = 1, isRefreshing: boolean = false) => {
    if (isRefreshing) {
      setRefreshing(true);
    } else {
      setLoadingMore(true);
    }

    try {
      const response = await useProject.getProjects(cityName, page);

      if (Array.isArray(response.projects)) {
        setProjects((prevProjects) => [...prevProjects, ...response.projects]);

        if (response.projects.length < 5) {
          setHasMore(false);
        }
      } else {
        console.error('Projects is not an array:', response.projects);
        setProjects([]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setTimeout(() => {
        if (isRefreshing) {
          setRefreshing(false);
        } else {
          setLoadingMore(false);
        }
      }, 1000);
    }
  };

  const fetchCities = useCallback(async () => {
    try {
      const fetchedCities = await useCity.getCities();
      setCities(fetchedCities);
      if (fetchedCities.length > 0) {
        setSelectedCity(fetchedCities[0].name);
      }
    } catch (error) {
      console.error('Failed to fetch cities:', error);
    }
  }, []);

  const handleSelectCity = (cityName: string) => {
    setSelectedCity(cityName);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePropertyClick = (project: Project) => {
    setSelectedProject(project);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
    setModalVisible(false);
  };

  const handleLoginClick = () => {
    setShowLoginFooter(true);
  };

  const handleEmailSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      setSuccessMessage('');
    } else {
      setEmailError('');
      const result = await login(email);
      if (result) {
        setSuccessMessage('Check your email for the magic link!');
        setEmailError('');
      } else {
        setSuccessMessage('');
      }
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="min-h-screen bg-white p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 pt-24">
      <style>{`
        .modal-background { opacity: 0; transition: opacity 0.5s ease-in-out; }
        .modal-background.open { opacity: 1; }
        .modal-slide { transform: translateY(100%); transition: transform 0.5s ease-in-out; }
        .modal-slide.open { transform: translateY(0); }
      `}</style>
      <div className="fixed top-0 left-0 w-full bg-white p-4 sm:p-6 z-10 shadow-md flex flex-col sm:flex-row sm:justify-between items-center">
        {isModalVisible && selectedProject && (
          <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal-background ${isModalVisible ? 'open' : ''}`}>
            <ListingDetails project={selectedProject} onClose={handleCloseModal} />
          </div>
        )}
        <div className="flex items-center justify-start w-full sm:w-auto">
          <img
            src={Logo} 
            alt="Logo"
            className="h-12 w-20 sm:h-16 sm:w-16 md:h-20 lg:h-24 xl:h-28 mr-4"
          />
          <DropDown cities={cities} selectedCity={selectedCity} onSelectCity={handleSelectCity} />
          {/* Hamburger menu button outside the menu for opening */}
          <div className="sm:hidden ml-auto cursor-pointer z-30" onClick={toggleMenu}>
            {!menuOpen && <div>Menu</div>}
          </div>
        </div>
        {/* Slide-in menu */}
        <SlideInMenu isOpen={menuOpen} onClose={toggleMenu} />
      </div>

      {refreshing && page === 1 ? (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <div className={`duration-1000 ${isModalVisible ? 'opacity-0' : 'opacity-100'}`}>
          <div className="grid grid-cols-1 gap-4 sm:gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 my-0">
            {projects.map((project) => (
              <PropertyCard key={project.id} project={project} onClick={() => { handlePropertyClick(project) }} onLoginClick={handleLoginClick} isAdmin={false} />
            ))}
          </div>
        </div>
      )}

      {hasMore && (
        <div ref={loaderDivRef} className="flex justify-center items-center h-20">
          {loadingMore && <Loader />}
        </div>
      )}

      {showLoginFooter && (
        <div className="w-full fixed bottom-0 left-0 right-0 bg-white p-8 shadow-lg">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border border-gray-300 p-2 rounded-lg mb-4 md:mb-0"
            />
            {emailError && <p className="text-red-500 text-sm mb-4">{emailError}</p>}
            {successMessage && <p className="text-green-500 text-sm mb-4">{successMessage}</p>}
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <button
              className={`w-full bg-blue-500 text-white py-4 px-4 rounded-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={handleEmailSubmit}
              disabled={loading}
            >
              <span>{loading ? 'Submitting...' : 'Explore property insights'}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
