import React, { useState } from "react";
import usePdfUpload from "../hooks/usePdfUpload";

interface PdfUploaderProps {
  onProcessComplete: (data: any) => void;
}

const PdfUploader: React.FC<PdfUploaderProps> = ({ onProcessComplete }) => {
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const { uploadPdf, uploading, error } = usePdfUpload();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setPdfFile(event.target.files[0]);
    }
  };

  const handlePdfUpload = () => {
    if (!pdfFile) {
      alert("Please select a PDF file to upload.");
      return;
    }

    uploadPdf(
      pdfFile,
      (result) => {
        onProcessComplete(result);
      },
      (uploadError) => {
        console.error("Upload failed:", uploadError);
      }
    );
  };

  return (
    <div className="mb-6">
      <label className="block text-gray-700">Upload Presentation</label>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        className="block w-full border mb-2 p-2"
      />
      <button
        type="button"
        onClick={handlePdfUpload}
        disabled={uploading}
        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
      >
        {uploading ? "Processing..." : "Upload and Process PDF"}
      </button>
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default PdfUploader;
