import React, { useState } from 'react';

interface ImageCarouselProps {
  pictures: string[];
  blurAmount?: number; // Optional blur amount prop to control image blur
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ pictures, blurAmount = 0 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startTouch, setStartTouch] = useState<number | null>(null);

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? pictures.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === pictures.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // Touch event handlers
  const handleTouchStart = (e: React.TouchEvent) => {
    setStartTouch(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!startTouch) return;
    const endTouch = e.changedTouches[0].clientX;
    const diff = startTouch - endTouch;

    // Detect swipe direction
    if (diff > 50) {
      goToNext(); // Swipe left (next slide)
    } else if (diff < -50) {
      goToPrevious(); // Swipe right (previous slide)
    }

    setStartTouch(null); // Reset the touch state
  };

  // Calculate the current dot state
  const getDotState = () => {
    if (currentIndex === 0) return [true, false, false]; // First dot active
    if (currentIndex === pictures.length - 1) return [false, false, true]; // Last dot active
    return [false, true, false]; // Middle dot active
  };

  const [firstDot, secondDot, thirdDot] = getDotState();

  return (
    <div
      className="relative w-full h-64 md:h-80 lg:h-96"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd} // Use onTouchEnd to detect swipe
    >
      {/* Carousel for images */}
      <div className="overflow-hidden w-full h-full">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {pictures.map((picture, index) => (
            <div key={index} className="w-full flex-shrink-0 min-h-64">
              <img
                src={picture}
                alt={`Image ${index + 1}`}
                className="w-full h-64 min-h-64 md:h-80 lg:h-96 object-cover"
                style={{
                  filter: `blur(${blurAmount}px)`, // Apply dynamic blur to images
                  transition: 'filter 0.3s ease', // Smooth transition for blur effect
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Dots Navigation */}
      <div className="absolute bottom-2 left-0 right-0 flex justify-center space-x-2">
        <button
          onClick={() => goToSlide(0)}
          className={`w-3 h-3 rounded-full ${firstDot ? 'bg-white' : 'bg-gray-400'}`}
        />
        <button
          onClick={() => goToNext()}
          className={`w-3 h-3 rounded-full ${secondDot ? 'bg-white' : 'bg-gray-400'}`}
        />
        <button
          onClick={() => goToSlide(pictures.length - 1)}
          className={`w-3 h-3 rounded-full ${thirdDot ? 'bg-white' : 'bg-gray-400'}`}
        />
      </div>
    </div>
  );
};

export default ImageCarousel;
