import { useState } from "react";
import apiClient from "../api/apiClientInterceptor";

const usePdfUpload = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const uploadPdf = async (
    file: File,
    onSuccess: (result: any) => void,
    onError?: (error: string) => void
  ) => {
    try {
      setUploading(true);
      setError(null);

      const formData = new FormData();
      formData.append("file", file);

      const response = await apiClient.post("project/analyzePDF", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        const data = response.data; // Axios already parses JSON
        onSuccess(data.result);
      } else {
        const errorMessage = response.data?.message || "Failed to process the PDF.";
        setError(errorMessage);
        onError?.(errorMessage);
      }
    } catch (err: any) {
      const errorMessage = err.response?.data?.message || "An error occurred while uploading the PDF.";
      setError(errorMessage);
      onError?.(errorMessage);
    } finally {
      setUploading(false);
    }
  };

  return { uploadPdf, uploading, error };
};

export default usePdfUpload;
