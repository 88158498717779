import React from 'react';

interface SlideInMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const SlideInMenu: React.FC<SlideInMenuProps> = ({ isOpen, onClose }) => {
  return (
    <div
      className={`fixed top-0 right-0 h-full bg-white shadow-lg p-4 z-40 flex flex-col items-start transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300`}
      style={{ width: '250px' }}
    >
      {/* Close button */}
      <div className="self-start mb-4 cursor-pointer" onClick={onClose}>
        <button
            className="absolute top-4 left-4 bg-white bg-opacity-80 text-gray-900 rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray-100 focus:outline-none"
            onClick={onClose}
            >
            &times;
        </button>
      </div>
      <div className="mt-16 w-full flex flex-col items-start">
        <a href="add-project" className="mb-4">Add Real Estate Project</a>
        <a href="edit-project" className="mb-4">Add Real Estate Project</a>
        <a href="#" className="mb-4">Log Out</a>  
      </div>
    </div>
  );
};

export default SlideInMenu;