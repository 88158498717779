// hooks/useProject.ts

import { getToken } from './useToken';
import apiClient from "../api/apiClientInterceptor";
import { Project } from '../models/Project';

const getProjects = async (cityName: string, page: number = 1, limit: number = 10) => {
  try {
    const { token, isLoggedIn } = await getToken();
    
    // Optional: Handle the case when the user is not logged in
    /*if (!isLoggedIn) {
      console.log('No token found');
      showModal();
      return;
    }*/

    // Make the API request with cityName, page, and limit
    const response = await apiClient.post(
      '/projects/all', // Updated endpoint to match projects
      { city_name: cityName, page, limit },  // Pass page and limit as part of the payload
      {
        headers: {
          'Authorization': `Bearer ${token || ''}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error); // Updated error message
    throw error; // Propagate the error for further handling
  }
};

const getDetails = async (identifier: string): Promise<{ data: Project; status: number }> => {
  try {
    const { token } = await getToken();

    const response = await apiClient.post(
      '/projects/getDetails',
      { identifier },
      {
        headers: { 'Authorization': `Bearer ${token || ''}` },
      }
    );

    return { data: response.data, status: response.status };
  } catch (error) {
    console.error('Error fetching project details:', error);
    throw error;
  }
};

const updateProject = async (identifier: string, data: Partial<Project>): Promise<{ data: Project; status: number }> => {
  try {
    const { token } = await getToken();

    const response = await apiClient.put(
      `/projects/editProject/${identifier}`,
      data,
      {
        headers: { 'Authorization': `Bearer ${token || ''}` },
      }
    );

    return { data: response.data, status: response.status };
  } catch (error) {
    console.error('Error updating project:', error);
    throw error;
  }
};

const addProject = async (data: Project): Promise<{ data: Project; status: number }> => {
  try {
    const { token } = await getToken();

    const response = await apiClient.post(
      '/projects/addProject',
      data,
      {
        headers: { 'Authorization': `Bearer ${token || ''}` },
      }
    );

    return { data: response.data, status: response.status };
  } catch (error) {
    console.error('Error adding project:', error);
    throw error;
  }
};

export default {
  getProjects,
  getDetails,
  updateProject,
  addProject
};
