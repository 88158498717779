import { useState, useEffect } from 'react';
import { Project } from '../models/Project'; // Import the Project model
import PropertyCard from '../components/PropertyCard';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClientInterceptor';

const AdminPage = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newProject, setNewProject] = useState<Project | null>(null);
  const [projects, setProjects] = useState<Project[]>([]); // Array of projects
  const [loading, setLoading] = useState(true);

  // Fetch projects on component mount
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await apiClient.get('projects/getMyProjects'); // Replace with your API endpoint
        setProjects(response.data.projects); // Set the projects state
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleOpenModal = (project: Project | null = null) => {
    setNewProject(project); // Set the project to be edited
    setIsModalVisible(true);
  };

  const handleEditClick = (identifier: string) => {
    navigate(`/editproject/${identifier}`);
  };

  const handleCloseModal = () => setIsModalVisible(false);

  const handleSaveProject = () => {
    if (newProject) {
      if (projects.some(project => project.id === newProject.id)) {
        // Update existing project
        setProjects(projects.map(project => project.id === newProject.id ? newProject : project));
      } else {
        // Add new project
        setProjects([...projects, newProject]);
      }
    }
    handleCloseModal();
  };

  return (
    <div className="min-h-screen bg-white p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 pt-24">
      <style>{`
        .modal-background {
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }
        .modal-background.open {
          opacity: 1;
        }
        .modal-slide {
          transform: translateY(100%);
          transition: transform 0.5s ease-in-out;
        }
        .modal-slide.open {
          transform: translateY(0);
        }
      `}</style>

      {/* Header Section with Add New Project Button */}
      <div className="fixed top-0 left-0 w-full bg-white p-4 sm:p-6 z-10 shadow-md flex flex-row justify-between items-center">
        <button
          onClick={() => navigate('/add-project')}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md"
        >
          Add New Project
        </button>
      </div>

      {/* Show Loader while fetching data */}
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <div className={`duration-1000 ${isModalVisible ? 'opacity-0' : 'opacity-100'}`}>
          <div className="grid grid-cols-1 gap-4 sm:gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 my-0">
            {projects.map((project) => (
              <PropertyCard
                key={project.id}
                project={project}
                onClick={() => handleEditClick(project.identifier || '')} // Provide an empty string fallback
                onLoginClick={() => {}}
                isAdmin={true}
                onEditClick={() => handleOpenModal(project)}
              />
            ))}
          </div>
        </div>
      )}

      {/* Modal for Adding/Editing Project */}
      {isModalVisible && newProject && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal-background open">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full modal-slide open">
            <h2 className="text-xl font-semibold mb-4">{newProject.id ? 'Edit Project' : 'Add New Project'}</h2>
            
            <input
              type="text"
              placeholder="Identifier"
              value={newProject.identifier}
              onChange={(e) => setNewProject({ ...newProject, identifier: e.target.value })}
              className="w-full border border-gray-300 p-2 rounded-lg mb-4"
            />
            <input
              type="number"
              placeholder="Price (First Unit)"
              value={newProject.projectFeatures.units[0]?.price || ''}
              onChange={(e) => setNewProject({
                ...newProject,
                projectFeatures: {
                  ...newProject.projectFeatures,
                  units: [
                    { ...newProject.projectFeatures.units[0], price: +e.target.value },
                    ...newProject.projectFeatures.units.slice(1)
                  ]
                }
              })}
              className="w-full border border-gray-300 p-2 rounded-lg mb-4"
            />
            {/*<input
              type="text"
              placeholder="City"
              value={newProject.city?.name || ''}
              onChange={(e) => setNewProject({ ...newProject, city: { ...newProject.city, name: e.target.value } })}
              className="w-full border border-gray-300 p-2 rounded-lg mb-4"
            />*/}

            <textarea
              placeholder="Description"
              value={newProject.description}
              onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
              className="w-full border border-gray-300 p-2 rounded-lg mb-4"
            />

            <div className="flex justify-end space-x-2">
              <button onClick={handleCloseModal} className="py-2 px-4 bg-gray-300 rounded-lg">Cancel</button>
              <button onClick={handleSaveProject} className="py-2 px-4 bg-blue-500 text-white rounded-lg">
                {newProject.id ? 'Save Changes' : 'Add Project'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
