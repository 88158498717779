import apiClient from "../api/apiClientInterceptor";
import { getToken } from './useToken';

const getAllAmenities = async () => {
    try {
        const { token } = await getToken();
        if (!token) {
            console.error('Authorization token is missing.');
            throw new Error('Token not available');
        }

        const response = await apiClient.get('/amenities/all', {
            headers: { 'Authorization': `Bearer ${token}` },
        });
        
        return response.data.data; // Ensure this returns only the `data` part of the response
    } catch (error) {
        console.error('Error fetching amenities:', error);
        throw error; // Propagate the error for further handling
    }
}

export default {
    getAllAmenities
};