import React, { useState } from 'react';
import useLogin from '../hooks/useLogin';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { login, loading, error } = useLogin();

  const handleEmailSubmit = async () => {
    if (!validateEmail(email)) {
        setEmailError('Please enter a valid email address');
        setSuccessMessage(''); // Clear success message if there's an error
      } else {
        setEmailError(''); // Clear error
  
        const result = await login(email); // Call the custom hook to make the API request
        if (result) {
          setSuccessMessage('Check your email for the magic link!'); // Set success message
          setEmailError(''); // Clear any email error if it exists
        } else {
          setSuccessMessage(''); // Clear success message if there's no result
        }
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-center mb-6">Login</h2>
        
        <div className="flex flex-col">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded-lg mb-4"
          />
          {/* Display error message */}
          {emailError && (
            <p className="text-red-500 text-sm mb-4">{emailError}</p>
          )}
          {/* Display success message */}
          {successMessage && (
            <p className="text-green-500 text-sm mb-4">{successMessage}</p>
          )}
          {/* Display error from the login attempt */}
          {error && (
            <p className="text-red-500 text-sm mb-4">{error}</p>
          )}
          <button
            className={`w-full bg-blue-500 text-white py-4 px-4 rounded-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleEmailSubmit}
            disabled={loading} // Disable the button while loading
          >
            <span>{loading ? 'Submitting...' : 'Login'}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
